$green-color-primary: #98c8bb;
$green-color-content: #223f37;
$green-color-light: #b9dad1;
$green-color-dark: #77b6a5;
$blue-color-primary: #708DC7;
$blue-color-content: #101828;
$blue-color-light: #95aad5;
$blue-color-dark: #4b70b9;
$pink-color-primary: #f2e0e5;
$pink-color-content: #953e56;
$pink-color-light: #ffffff;
$pink-color-dark: #e3bcc7;

html {
  scroll-behavior: smooth;
}

.app {
  text-align: center;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  scrollbar-color: rgb(98 84 243);
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 1.5rem;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background: $green-color-primary;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}