.cake-shop-section {
    display: flex;
    place-content: center;
    padding-bottom: 60px;
    
    .cake-shop-container {
        max-width: 807px;
        
        .title {
            font-family: "Chequersdemo";
            font-size: 26px;
            height: 10vh;
            align-content: end;
            padding-bottom: 10px;
        }
        
        .card-container {
            &-center {
                display: flex;
                justify-content: center;
            }

            &-end {
                display: flex;
                justify-content: flex-end;
            }

            &-start {
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}

.cake-shop-full-screen-section {
    display: flex;
    place-content: center;
    padding-bottom: 60px;
    
    .cake-shop-full-screen-container {
        max-width: 1020px;
        
        .title {
            font-family: "Chequersdemo";
            font-size: 30px;
            height: 10vh;
            align-content: end;
        }
        
        .card-container {
            &-center {
                display: flex;
                justify-content: center;
            }

            &-end {
                display: flex;
                justify-content: flex-end;
            }

            &-start {
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}

.cake-shop-mobile-section {
    display: flex;
    place-content: center;
    
    .cake-shop-mobile-container {
        width: 100%;
        padding-bottom: 80px;
        padding-left: 16px;
        padding-right: 16px;
        
        .title {
            margin-top: 60px;
            margin-bottom: 40px;
            font-family: "Chequersdemo";
            font-size: 26px;
        }

        .card-container {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }
    }
}

.swiper-3d .swiper-slide-shadow {
    background: none;
}