.appbar-section {
    display: flex;
    place-content: center;

    .appbar {
        max-width: 855px;
        height: 15vh;
        justify-content: center;

        .button {
            font-size: 16px;
            font-family: "BozonRegular";
            line-height: 20px;
        }

        .with-margin {
            margin-right: 30px;
        }
    }

    .appbar-full-screen {
        max-width: 1072px;
        height: 15vh;
        justify-content: center;

        .button {
            font-size: 18px;
            font-family: "BozonRegular";
            line-height: 22px;
        }

        .with-margin {
            margin-right: 30px;
        }
    }

    .appbar-mobile {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
    }
}