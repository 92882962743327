.about-me-section {
    padding-bottom: 60px;
    padding-top: 60px;
    display: flex;
    place-content: center;
    background: #F2E0E5;

    .about-me-container {
        width: 807px;
    }

    .about-me-container-without-margin {
        margin-left: 30px;
        margin-right: 30px;
    }

    .about-me-text {
        padding: 20px;
        overflow-y: auto;
        height: 300px;
        width: 100%;
        overflow-x: hidden;
        direction: rtl
    }

    .about-me-text-mobile {
        .view-more-button {
            color: #FAFAFA;
        }
    }
}

.about-me-full-screen-section {
    padding-bottom: 80px;
    padding-top: 80px;
    display: flex;
    place-content: center;
    background: #F2E0E5;

    .about-me-full-screen-container {
        width: 1024px;
    }

    .about-me-full-screen-container-without-margin {
        margin-left: 30px;
        margin-right: 30px;
    }

    .about-me-full-screen-text {
        padding: 20px;
        overflow-y: auto;
        height: 300px;
        width: 100%;
        overflow-x: hidden;
        direction: rtl
    }
}

.about-me-mobile-section {
    padding-bottom: 40px;
    padding-top: 80px;
    display: flex;
    place-content: center;
    background: linear-gradient(to bottom, white 0%, white 25%, #F2E0E5 25%, #F2E0E5 100%);

    .about-me-mobile-container {
        padding-left: 16px;
        padding-right: 16px;
    }

    .about-me-text {
        padding: 20px;
        overflow-y: auto;
        height: 300px;
        width: 100%;
        overflow-x: hidden;
        direction: rtl
    }

    .about-me-text-mobile {
        .view-more-button {
            color: #FAFAFA;
        }
    }
}