.contact-with-qr {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;

    &-container {
        width: 807px;

        &__avatar {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

.contact-with-qr-full-screen {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 80px;

    &-container {
        width: 1024px;

        &__avatar {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

.contact-with-qr-mobile {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;

    &-container {
        width: 100%;
        margin-left: 16px;
        margin-right: 16px;

        &__avatar {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}